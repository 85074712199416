import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';

export const ProductListHeader = styled('h2')`
  font-size: 1.9rem;
  padding: 1rem 0 2rem;
  width: 100%;
  text-align: center;
  color: ${theme.productPage.productList.headerColor};
`;

export const ProductListSubHeader = styled('h3')`
  padding: 0 0;
  width: 100%;
  text-align: center;
  color: ${theme.productPage.productList.subHeaderColor};
`;

export const ProductInfoMarginBottom = `
  margin-bottom: 0.6rem;
  ${theme.above.md} {
    margin-bottom: 1rem;
  }
`;
