import React from 'react';
import { styled } from 'linaria/react';
import { GridWrapper } from '../../CategoryPage/ProductGrid';
import { ProductCard } from '../../CategoryPage/ProductCard';
import t from '@jetshop/intl';
import {
  ProductListHeader,
  ProductListSubHeader,
} from '../../ProductPage/StyledComponents';
import AddToCart from '../../Cart/AddToCart';
import { useEffect } from 'react';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import { ScrollBox } from '../ScrollBox';
import { theme } from '../../Theming/Theming';
import { ButtonUI } from '../ButtonUI';
import ProductLink from '@jetshop/ui/ProductLink';

const ProductRowWrapper = styled('div')`
  width: 100%;

  > header {
    text-align: center;
  }
  &.header-look {
    ${theme.above.lg} {
      display: flex;
      > header {
        width: 19%;
        display: flex;
        margin-right: 1%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 5px;
        position: relative;
      }
      > div {
        width: 80%;
      }
    }
  }

  //SLIDER WIDTH
  .slider-items {
    .product-card {
      margin-right: 2%;
      ${theme.only.sm} {
        width: 55%;
        margin-right: 4%;
      }
      ${theme.only.md} {
        width: 37%;
        margin-right: 3%;
      }
      ${theme.only.lg} {
        width: 27%;
      }
      ${theme.above.xl} {
        width: 21%;
      }
    }
  }
  &.clean {
    .slider-items {
      .product-card {
        margin-right: 2%;
        ${theme.only.sm} {
          width: 19%;
          margin-right: 4%;
        }
        ${theme.only.md} {
          width: 15%;
          margin-right: 3%;
        }
        ${theme.only.lg} {
          width: 12%;
        }
        ${theme.above.xl} {
          width: 9%;
        }
      }
    }
    margin-top: 3rem;
    .product-list-header {
      color: ${theme.colors.primaryDark};
      text-align: left;
      font-size: 1.4rem;
    }
    .product-card {
      .product-card-detail {
        display: none;
      }
    }
  }
  &.tight {
    .product-card-detail {
      margin: 0.5rem 0 0.1rem;
    }
    header {
      > p {
        font-size: 0.7rem;
      }
      > a > h3 {
        font-size: 0.9rem;
      }
    }
    [data-flight-price] > div {
      font-size: 0.9rem;
    }
    button.list {
      margin-top: 5px;
      font-size: 0.7rem;
      height: 30px;
    }
    .with-badge {
      img {
        width: 40px;
      }
    }
    svg {
      font-size: 1rem;
    }
  }
  &.tight .slider-items {
    .product-card {
      margin-right: 2%;
      ${theme.only.sm} {
        width: 45%;
        margin-right: 4%;
      }
      ${theme.only.md} {
        width: 27%;
        margin-right: 3%;
      }
      ${theme.only.lg} {
        width: 30%;
      }
      ${theme.above.xl} {
        width: 30%;
      }
    }
  }
`;

const RowProduct = ({ product, quickBuy }) => {
  return (
    <ProductCard product={product} categoryPath={null}>
      {quickBuy && (
        <>
          {!product.hasVariants &&
          product.stockStatus &&
          product.stockStatus.buyable ? (
            <AddToCart
              product={product}
              quantity={1}
              selectedVariation={product}
            >
              {(add) => (
                <ButtonUI
                  css={{ marginTop: '1rem' }}
                  className="buy list"
                  onClick={() => {
                    add();
                  }}
                >
                  {t('Add to cart')}
                </ButtonUI>
              )}
            </AddToCart>
          ) : (
            <ButtonUI className="hollow list add-to-cart">
              <ProductLink product={product} categoryPath={null}>
                {t('Go to')}
              </ProductLink>
            </ButtonUI>
          )}
        </>
      )}
    </ProductCard>
  );
};

export const RowProducts = ({
  category,
  slider,
  title,
  subTitle,
  quickBuy,
  headerLook,
  ...rest
}) => {
  const products = category?.products?.result || [];

  const listName = 'product-row: ' + category.name;

  //COMBINE STATIC WITH SLIDER VIEW
  const hybridSlider = products.length === 4 && slider;

  //TRACK LIKE PRODUCTGRID
  const track = useTracker();
  useEffect(() => {
    const products = category?.products?.result || [];
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;
    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, category, track]);

  if (!products) return null;

  return (
    <ProductRowWrapper
      data-testid="product-grid"
      className={`${rest.className} product-row-wrapper ${
        headerLook ? 'header-look' : ''
      }`}
    >
      <header className="product-list-header">
        {subTitle && (
          <ProductListSubHeader className="product-list-sub-header">
            {subTitle}
          </ProductListSubHeader>
        )}
        {title && (
          <ProductListHeader className="product-list-main-header">
            {title}
          </ProductListHeader>
        )}
      </header>
      {slider ? (
        <ScrollBox
          className={hybridSlider ? 'hybrid' : ''}
          progressBar={rest.progressBar}
        >
          {products.map((product) => (
            <RowProduct
              product={product}
              quickBuy={quickBuy}
              key={category.id + '-' + product.id}
            />
          ))}
        </ScrollBox>
      ) : (
        <GridWrapper>
          {products.map((product) => {
            return (
              <RowProduct
                product={product}
                quickBuy={quickBuy}
                key={category.id + '-' + product.id}
              />
            );
          })}
        </GridWrapper>
      )}
    </ProductRowWrapper>
  );
};
